export class Depot {
	constructor ({ id = null, name = '', allowed_transport = [], status_id = 100 } = {}) {
		this.id = id
		this.name = name
		this.allowed_transport = allowed_transport
		this.status_id = status_id
	}
}

export function createDepot (data) {
	return new Depot(data)
}
