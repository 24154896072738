import API from '@/api/depots'
import { Depot, createDepot } from '@/models/Depot'
import { createFormErrors } from '@/store/utils'
import { getField, updateField } from 'vuex-map-fields'

const state = () => {
	return {
		data: new Depot(),

		formErrors: createFormErrors(new Depot()),
		loading: false,
		saving: false,
		dirty: false
	}
}

const getters = {
	getField
}

const mutations = {
	setData (state, data = {}) {
		state.data = createDepot(data)
		state.formErrors = createFormErrors(state.data)
	},
	setErrors (state, p = {}) {
		state.formErrors = createFormErrors(state.data, p)
	},
	setLoading (state, b) {
		state.loading = b
	},
	setSaving (state, b) {
		state.saving = b
	},
	updateField
}

const actions = {
	loadItem (ctx, id) {
		ctx.commit('setLoading', true)
		return API.loadItem(id).then(({ data }) => {
			ctx.commit('setData', data)

			return data
		}).catch(({ response: { data } }) => {
			ctx.commit('core/snackbar/show', { text: data.message }, { root: true })

			throw data
		}).finally(() => {
			ctx.commit('setLoading', false)
		})
	},
	saveItem (ctx) {
		ctx.commit('setSaving', true)

		return API.saveItem(ctx.state.data).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			ctx.commit('core/snackbar/show', { text: data.message }, { root: true })
			if (data.formErrors) ctx.commit('setErrors', data.formErrors)

			throw data
		}).finally(() => {
			ctx.commit('setSaving', false)
		})
	}
}

export default function (store, storeName) {
	if (store.state[storeName]) return true

	store.registerModule(storeName, {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	})
}
