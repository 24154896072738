<template>
<w-dialog v-model="showDialog" v-bind="dialogProps" :loading="loading" :processing="saving" @action="processActions">
	<v-toolbar dark color="primary" slot="title" flat>
		<v-toolbar-title>{{ name || 'New item' | translate }}</v-toolbar-title>
	</v-toolbar>

	<v-container grid-list-md>
		<v-row wrap>
			<v-col xs12><v-text-field v-model="name" :label="$t('Title')" :error-messages="formErrorsMsg.name"></v-text-field></v-col>
		</v-row>
		<v-row wrap>
			<v-col xs12><depot-selector v-model="allowed_transport" :label="$t('Allowed transport to')" :error-messages="formErrorsMsg.allowed_transport" multiple></depot-selector></v-col>
		</v-row>
	</v-container>
</w-dialog>
</template>
<script>
import entityStoreInit from '@/store/depots/entity'
import DepotSelector from './DepotSelector'

import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const entityStoreName = 'depotsEntity'

export default {
	data () {
		return {
			dialogProps: {
				persistent: true,
				maxWidth: 500,
				buttons: [
					{ name: this.$i18n.translate('DlgCancel'), action: 'close' },
					{ name: this.$i18n.translate('DlgSave'), action: 'save', primary: true, color: 'primary', disabled: false }
				],
				buttonsAlign: 'right'
			},
			tab: null,
			showDialog: false
		}
	},
	computed: {
		...mapFields(entityStoreName, [
			'data.name', 'data.allowed_transport'
		]),
		...mapState(entityStoreName, [ 'loading', 'saving', 'formErrors' ]),
		formErrorsMsg () {
			return Object.keys(this.formErrors).reduce((r, k) => {
				r[k] = this.$options.filters.translate(this.formErrors[k])
				return r
			}, {})
		}
	},
	methods: {
		processActions (a) {
			if (a.action === 'save') {
				this.$store.dispatch(entityStoreName + '/saveItem').then((data) => {
					this.$emit('data', data)
					this.close()
				}).catch(() => { })
			}

			if (a.action === 'close') {
				// check if data was changed
				// if yes, than bubble action to parent
				this.close()
			}
		},
		init (id) {
			if (id) {
				this.$store.dispatch(entityStoreName + '/loadItem', id)
			} else {	// new item
				this.$store.commit(entityStoreName + '/setData')
			}

			this.$nextTick(() => {
				this.showDialog = true
			})
		},
		close () {
			this.showDialog = false
		}
	},
	beforeCreate () {
		entityStoreInit(this.$store, entityStoreName)
	},
	destroy () {
		this.$store.unregisterModule(entityStoreName)
	},
	components: {
		DepotSelector
	}
}
</script>
